import React from "react"
import { isMobile } from "react-device-detect"
import { graphql, Link } from "gatsby"
import Layout from "components/layout"
import { Heading } from "../components/Hero"
import Portfolio from "../components/Portfolio/Portfolio"
import { Container, Section } from "../components/Layouts"
import SEO from "components/seo"
import ImageMeta from "../components/ImageMeta"
import Button from "../components/Buttons/Button"
import { MarketingAnalysisFormTwo } from "../components/Forms"
import { LeftPopup } from "../components/Popups"

const BlogsQuery = ({ data, path, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const featuredBlogs = data.allBlogsJson.nodes
  const topFourBlogs = featuredBlogs.slice(0, 4)
  const gridBlogs = featuredBlogs.slice(4)

  const extraBlogs = [
    ...data.otherBlogs.nodes,
    ...data.markdownBlogs.nodes
  ].map(b => {
    return {
      id: b.id,
      title: "/" + b.title,
      heading: b.query.heading,
      label: b.query.label,
      text: b.query.text
    }
  })

  gridBlogs.push(...extraBlogs)

  const {
    heroSection: { content }
  } = post

  const renderTopImageBlogs = () => {
    return topFourBlogs.slice(0, 4).map(blog => {
      const { cardImage, heading, id, label, title } = blog

      return (
        <div key={id} className="blog-card-container">
          {isMobile ? (
            <Link to={title}>
              <div className="blog-card__image-container">
                <div className="blog-card__top-section container">
                  <Button
                    className="blog-card__button"
                    buttonText={label}
                    href={title}
                    color="light"
                  />
                  <p className="blog-card__heading">{heading}</p>
                </div>
                <ImageMeta
                  cloudName="nuvolum"
                  className="blog-card-image"
                  publicId={cardImage}
                  responsive
                  width="auto"
                />
              </div>
            </Link>
          ) : (
            <div className="blog-card__image-container">
              <div className="blog-card__top-section container">
                <Button
                  className="blog-card__button"
                  buttonText={label}
                  href={title}
                  color="white"
                />
                <p className="blog-card__heading">{heading}</p>
              </div>
              <ImageMeta
                cloudName="nuvolum"
                className="blog-card-image"
                publicId={cardImage}
                responsive
                width="auto"
              />
            </div>
          )}
        </div>
      )
    })
  }

  const renderBlogGrid = () => {
    return gridBlogs.map(blog => {
      const { heading, id, label, title, text } = blog

      const stripSymbols = text => {
        return text.replace(/<[^>]*>/g, "") // removes html
      }
      return (
        <div key={id} className="blog-card-grid__item">
          {/* <Button
            className="blog-card__button"
            buttonText={label}
            href={title}
          /> */}
          <Link
            to={title}
            title={`Read more about ${heading}`}
            className="blog-card-item__container">
            <h2 className="blog-card-item__heading">{heading}</h2>
            <p className="blog-card-item__text">{stripSymbols(text)}</p>
          </Link>
        </div>
      )
    })
  }

  return (
    <Layout path={path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />

      {/* <LeftPopup startHidden pageUrl={location.pathname} /> */}

      <Heading content={content} />
      <Section zeroTop>
        <Container>
          <Button
            buttonText="Learn More"
            href="#insights-form/"
            destination="anchor"
          />
        </Container>
      </Section>
      <Section className="blogs-query-container container">
        {renderTopImageBlogs()}
      </Section>
      <Section className="blog-card-grid__container container">
        {renderBlogGrid()}
      </Section>

      <Section colorBack className="relative">
        <Container>
          <MarketingAnalysisFormTwo
            id="insights-form"
            origin={location.href}
            phone="(415) 304-8425"
          />
        </Container>
      </Section>

      <Portfolio />
    </Layout>
  )
}

export default BlogsQuery

export const pageQuery = graphql`
  query blogsQueryPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heroSection {
          content
        }
      }
    }
    allBlogsJson(
      filter: { hasThisPage: { eq: true } }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        id
        cardImage
        heading
        label
        title
        text
      }
    }
    otherBlogs: allUniquePagesJson(
      filter: { query: { type: { eq: "blog" } } }
    ) {
      nodes {
        id
        title
        query {
          heading
          label
          text
        }
      }
    }
    markdownBlogs: allMarkdownPagesJson(
      filter: { query: { type: { eq: "blog" } } }
    ) {
      nodes {
        id
        title
        query {
          heading
          label
          text
        }
      }
    }
  }
`
